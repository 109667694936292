.app {
  background: #fefef6;
  display: flex;
  flex: 1;
  overflow: scroll;
  width: 100%;
}

.slide-enter-active,
.slide-exit-active {
  transition: transform 750ms ease-out;
  will-change: transform;
}

.slide-enter {
  transform: translate3d(0, 0, 0) translateX(100%);
}

.slide-enter-active {
  transform: translate3d(0, 0, 0) translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0) translateX(0%);
}

.slide-exit-active {
  transform: translate3d(0, 0, 0) translateX(-100%);
}

/* reverse */

.slide-exit-active-reverse,
.slide-enter-reverse {
  transition: transform 750ms ease-out;
  will-change: transform;
}

.slide-enter-reverse {
  transform: translate3d(0, 0, 0) translateX(-100%);
}

.slide-enter-active-reverse {
  transform: translate3d(0, 0, 0) translateX(0%);
}

.slide-exit-reverse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0) translateX(0%);
}

.slide-exit-active-reverse {
  transform: translate3d(0, 0, 0) translateX(100%);
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}
