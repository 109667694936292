@font-face {
    font-family: 'Nice Nineties';
    src: url('NiceNineties-RegularItalic.woff2') format('woff2'),
    url('NiceNineties-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nice Nineties';
    src: url('NiceNineties-Regular.woff2') format('woff2'),
    url('NiceNineties-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

